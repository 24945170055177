<!-- eslint-disable vue/no-v-html -->
<template>
  <b-modal
    id="modal-preview-my-invoice"
    ref="refModal"
    title="Chi tiết hóa đơn"
    hide-footer
    size="lg"
    scrollable
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="showModal"
    @hidden="resetModal"
  >
    <div class="d-flex justify-content-center my-1">
      <b-spinner v-if="isLoading" class="text-center" variant="secondary" />
    </div>

    <iframe
      v-if="pdfUrl && !isLoading"
      :src="pdfUrl"
      style="width: 100%; height: 80vh; border: none"
    />
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import { required } from "@validations";
import useInvoicePreviewModal from "./usePreviewInvoiceModal";

export default {
  components: {
    BModal,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props) {
    const {
        itemLocal,
    resetItemLocal,
    resetModal,
    fetchInvoice,
    showModal,
    isLoading,
    pdfUrl,
    } = useInvoicePreviewModal(props);

    return {
        itemLocal,
    resetItemLocal,
    resetModal,
    fetchInvoice,
    showModal,
    isLoading,
    pdfUrl
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
</style>
