<template>
  <div>
    <b-card no-body>
    <b-card-header>
      <b-card-title>Hoá đơn</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-row class="mt-2">
        <b-col cols="12">
          <vue-good-table
            style-class="vgt-table striped bordered"
            mode="remote"
            :total-rows="rows.length"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: false,
            }"
          >
            <template slot="loadingContent">
              <b-spinner label="Loading" type="grow" />
            </template>
            <template slot="table-row" slot-scope="props">
              <!-- Column: Code -->
              <span v-if="props.column.field === 'code'">
                <b-link
                  v-b-modal.modal-preview-my-invoice
                  class="font-weight-bold"
                  @click="onPreviewInvoice(props.row)"
                >
                  {{ props.row.code }}
                </b-link>
              </span>
              <!-- Column: Customer -->
              

              <!-- Column: Total -->
              <span v-else-if="props.column.field === 'total'">
                {{ Number(props.row.total).toLocaleString() }} VNĐ
              </span>

              <!-- Column: Paid -->
              <span v-else-if="props.column.field === 'paid'">
                {{
                    Number(props.row.paid + props.row.prepaid).toLocaleString()
                  }} VNĐ
              </span>

              <!-- Column: Remain -->
              <span v-else-if="props.column.field === 'remain'">
                {{ Number(props.row.remain).toLocaleString() }} VNĐ
              </span>


              <!-- Column: Due date -->
              <span v-else-if="props.column.field === 'dueDate'" class="flex">
                <div>
                  <span>
                    {{ parseDateToString(props.row.dueDate) }}
                  </span>

                  <b-badge
                    v-if="props.row.remain !== 0"
                    pill
                    :variant="
                      getRemainDaysVariant(props.row.remainDays).variant
                    "
                    class="font-small-1"
                  >
                    {{ getRemainDaysVariant(props.row.remainDays).text }}
                  </b-badge>
                </div>
              </span>

              <span v-else-if="props.column.field === 'month'" class="flex">
                <div>
                  <span>
                    {{ parseDateToString(props.row.month, "MM-YYYY") }}
                  </span>
                </div>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <table-actions
                  name="Hóa đơn"
                  :disable-delete="props.row.approve"
                  :disable-edit="false"
                  :hide-print="false"
                  :hide-payment="
                    true
                  "
                  :payment-modal="'modal-invoice-payment'"
                  modal="modal-invoice"
                  :hide-delete="true"
                  :hide-edit="true"
                >
                  <template slot="other-buttons">
                    <b-button
                      v-b-modal.modal-preview-my-invoice
                      v-b-tooltip.hover.v-info
                      variant="info"
                      class="btn-icon mr-50"
                      title="Xem hóa đơn"
                      size="sm"
                      @click="onPreviewInvoice(props.row)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>
                    <b-button
                      v-if="!props.row.approve && $can('approve', 'invoice')"
                      v-b-tooltip.hover.v-success
                      variant="success"
                      class="btn-icon mr-50"
                      title="Duyệt"
                      size="sm"
                    >
                      <feather-icon icon="CheckSquareIcon" />
                    </b-button>
                   
                  </template>
                </table-actions>
              </span>
              <span v-else-if="props.column.field === 'creator'">
                {{
                  props.row.creator && props.row.creator.user
                    ? props.row.creator.user.name
                    : props.row.owner.name
                }}
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props"> </template>
            <div slot="emptystate">
              <div class="text-center text-muted">Không có bản ghi nào!</div>
            </div>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
  <preview-invoice-modal :item="invoiceItem"/>

    </div>
  
</template>

<script>
/* eslint-disable import/no-cycle */
import {
  VBTooltip,
  BButton,
  VBModal,
  BTabs,
  BTab,
  BSpinner,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BLink,
  BBadge
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import useMyInvoice from "./useMyInvoice";
import { parseDateToString,  } from '@/auth/utils';
import TableActions from "@/views/components/TableActions.vue";
import PreviewInvoiceModal from "./PreviewInvoiceModal.vue";
import { props } from "vue-prism-component";


export default {
  components: {
    BButton,
    BTabs,
    BTab,
    BSpinner,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueGoodTable,
    BLink,
    PreviewInvoiceModal,
  BBadge,
  TableActions
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
  },
  setup() {
    const { isLoading, fetchData, columns, rows , getRemainDaysVariant, onPreviewInvoice, invoiceItem} = useMyInvoice();

    const { t } = useI18nUtils();

    return {
      isLoading,
      fetchData,
      columns,
      rows,
      parseDateToString,
      getRemainDaysVariant,
      onPreviewInvoice,
      invoiceItem
    };
  },

  created() {
    this.fetchData();
  },
};
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}

</style>