import { useRouter } from "@/@core/utils/utils";
import store from "@/store";
import toast from "@/utils/toast";
import invoiceStoreModule from "@/views/finance/invoice/invoiceStoreModule";
import { ref } from "@vue/composition-api";

export default function useMyInvoice() {
  const STORE_MODULE_NAME = "invoice";

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }

  const toastification = toast();

  const { route } = useRouter();
  const { query } = route.value;
  const isLoading = ref(false);
  const item = ref(JSON.parse(JSON.stringify(query)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(query));
  };
  const rows = ref([]);
  const invoiceItem = ref({id: 0})
  const columns = [
    {
      label: "Mã hóa đơn",
      field: "code",
      width: "140px",
    },
    {
      label: "Thao tác",
      field: "action",
      tdClass: "text-center",
      sortable: false,
      width: "100px",    },
    {
      label: "Tháng",
      field: "month",
      width: "100px",
    },
    {
      label: "Học sinh",
      field: "student.name",

    },
    {
      label: "Số tiền",
      field: "total",
      type: "number",
      width: "150px",
    },
    {
      label: "Đã thanh toán",
      field: "paid",
      type: "number",
      width: "150px",
    },
    {
      label: "Chưa thanh toán",
      field: "remain",
      type: "number",
      width: "150px",
    },
    {
      label: "Hạn TT",
      field: "dueDate",
      width: "220px",
    },
   
  ];

  const fetchData = () => {
    isLoading.value = true;
    store
      .dispatch("invoice/searchInvoice", item.value)
      .then((response) => {
        isLoading.value = false;
        rows.value = response.data
      })
      .catch((error) => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const getRemainDaysVariant = remainDays => {
    if (remainDays > 30) {
      return { variant: "light-primary", text: `Còn ${Math.abs(remainDays)} ngày` };
    }
    if (remainDays > 0) {
      return { variant: "light-warning", text: `Còn ${Math.abs(remainDays)} ngày` };
    }
    if (remainDays === 0) {
      return { variant: "light-warning", text: `Quá hạn hôm nay` };
    }
    return { variant: "light-danger", text: `Quá hạn ${Math.abs(remainDays)} ngày` };
  };

  const onPreviewInvoice = (invoice) => {
    invoiceItem.value = invoice
  }

  return {
    fetchData,
    isLoading,
    columns,
    rows,
    getRemainDaysVariant,
    onPreviewInvoice,
    invoiceItem
  };
}
