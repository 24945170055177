/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { getUserConfiguration } from '@/auth/utils';
import invoiceStoreModule from '@/views/finance/invoice/invoiceStoreModule';


export default function useInvoicePreviewModal(props) {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }
  const toastification = toast();

  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const resetModal = () => {
    resetItemLocal();
  };



  //
  const pdfUrl = ref(null);
  const isLoading = ref(false);

  const fetchInvoice = () => {
    isLoading.value = true;
    store
      .dispatch('invoice/previewInvoice', itemLocal.value.id)
      .then(response => {
        pdfUrl.value = response.data;
        isLoading.value = false;
      })
      .catch(error => {
        toastification.showToastError(error);
        isLoading.value = false;
      });
  };

  const showModal = () => {
    resetModal();
    fetchInvoice();
  };





  return {
    itemLocal,
    resetItemLocal,
    resetModal,
    fetchInvoice,
    showModal,
    isLoading,
    pdfUrl
  };
}
